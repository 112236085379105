import documentApiConstructor from '@/api/document/document-api';

const documentApi = documentApiConstructor('nl');

// initial state
const state = {
  nlLrDocument: null,
  nlLrDocumentRelated: null,
};

// getters
const getters = {
  nlLrDocument: (state) => state.nlLrDocument,
  nlLrDocumentRelated: (state) => state.nlLrDocumentRelated,
};

// actions
const actions = {
  async nlLrGetDocumentById({ commit }, args) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const document = await documentApi.getById(args.id, { highlightWord: args.highlightWord });
    commit('nlLrSetDocument', document.data);
    return document;
  },

  async nlLrGetRelated({ commit }, refs) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const related = await documentApi.getRelated(refs);
    commit('nlLrSetDocumentRelated', related.data);
    return related;
  },
};

// mutations
const mutations = {
  nlLrSetDocument(state, document) {
    state.nlLrDocument = document;
  },

  nlLrSetDocumentRelated(state, related) {
    state.nlLrDocumentRelated = related;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
