import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.teal.darken1,
        secondary: '#093c5d',
        accent: '#2A3F54',
        error: colors.red.darken1,
        warning: '#FB8C00',
        success: colors.teal,
        info: colors.lightBlue.darken1,
        anchor: colors.blueGrey.darken2,
      },
      dark: {
        primary: colors.teal.darken1,
        secondary: '#093c5d',
        accent: '#2A3F54',
        error: colors.red.darken1,
        warning: '#FB8C00',
        success: colors.teal,
        info: colors.lightBlue.darken1,
        anchor: colors.blueGrey.darken2,
      },
    },
  },
});
