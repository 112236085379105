// initial state
const state = {
  documentId: null,
  highlightWord: null,
  isEmailLink: null,
};

// getters
const getters = {
  getDocumentId: (state) => state.documentId,
  getHighlightedWord: (state) => state.highlightWord,
  getIsEmailLink: (state) => state.isEmailLink,
};

// mutations
const mutations = {
  setHighlightedWord(state, payload) {
    state.highlightWord = payload;
  },
  setDocumentId(state, payload) {
    state.documentId = payload;
  },
  setIsEmailLink(state, payload) {
    state.isEmailLink = payload;
  },
};

const actions = {
  setHighlightedWord({ commit }, payload) {
    commit('setHighlightedWord', payload);
  },
  setDocumentId({ commit }, payload) {
    commit('setDocumentId', payload);
  },
  setIsEmailLink({ commit }, payload) {
    commit('setIsEmailLink', payload);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
