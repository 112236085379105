import LoginForm from './views/AccountLogin.vue';
import { ifNotAuthenticated } from '../../router/routing_helpers';

export default [
  {
    path: '/login',
    name: 'login',
    component: LoginForm,
    beforeEnter: ifNotAuthenticated,
  },
];
