<template>
  <article>
    <div>
      <h1>{{ $t('maintenance.title') }}</h1>
      <div>
          <p>{{ $t('maintenance.message1') }}</p>
          <p>{{ $t('maintenance.message2') }}</p>
          <p>{{ $t('maintenance.messageQuestions') }} <a href="mailto:info@polpo.nl">info@polpo.nl</a>.</p>
          <p>&mdash; {{ $t('maintenance.polpoTeam') }}</p>
          <p class="text-body-1 text--secondary">{{ $t('maintenance.reconnect') }} {{ reconnectAfterSeconds }}s</p>
      </div>
    </div>
    <img src="/logo-no-text-transparant-192x192.png"/>
  </article>
</template>

<script>
import i18n from '@/i18n';

export default {
  data() {
    return {
      i18n,
      reconnectTimeoutMs: 30000, // 30 sec
      reconnectAfterSeconds: 30,
    };
  },

  mounted() {
    // Note: We need to explicitly set the locale here, because userPreferences are not loaded.
    i18n.locale = JSON.parse(localStorage.getItem('locale')) || 'nl';

    // Redirect to login, which will redirect to the right dashboard if the user is
    // already logged in.
    setTimeout(() => {
      window.location.replace('/login');
    }, this.reconnectTimeoutMs);

    // Visual countdown
    this.reconnectAfterSeconds -= 1; // Immediately subtract once
    setInterval(() => {
      if (this.reconnectAfterSeconds > 0) this.reconnectAfterSeconds -= 1;
    }, 1000);
  },
};
</script>

<style scoped>
  body {
    font-family: Roboto,sans-serif;
    color: #333;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    font-size: 2.5em;
    color: #2a5db0;
    margin-bottom: 1em;
  }

  article {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    text-align: left;
    max-width: 1000px;
    margin: 0 auto;
    padding: 1em;
    padding-top: 150px;
    font-size: 1.2em;
  }

  img {
    margin-top: 1em;
  }

  @media only screen and (max-width: 800px) {
    article {
      flex-direction: column;
      align-items: center;
    }
  }

  a, a:visited {
    color: #dc8100 !important;
    text-decoration: none;
  }

  a:hover {
    color: #2a5db0 !important;
  }
</style>
