import { ifAuthenticated } from '../../router/routing_helpers';

export default [
  {
    path: '/list-manager/:listId?',
    name: 'list-manager',
    beforeEnter: ifAuthenticated,
    // Lazy loading list-manager
    component: () => import(/* webpackChunkName: "ListManager" */ './views/ListManager.vue'),
  },
];
