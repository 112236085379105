import store from '@/store';

export const loginAuthenticate = (to, from, next) => {
  if (store.getters.authIsAuthenticated) {
    if (store.getters.getIsEmailLink) {
      const { getters: { getDocumentId, getHighlightedWord } } = store;
      next(`/document/${getDocumentId}/${getHighlightedWord}`);
    } else {
      next();
    }
  } else {
    next('/login');
  }
};

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.authIsAuthenticated) {
    next();
  } else {
    const { params: { documentId, highlightWord } } = to;
    if (documentId !== null && highlightWord !== null) {
      store.dispatch('setDocumentId', documentId);
      store.dispatch('setHighlightedWord', highlightWord);
      store.dispatch('setIsEmailLink', true);
    }
    next('/login');
  }
};

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.authIsAuthenticated) {
    next();
  } else {
    next('/dashboard');
  }
};
