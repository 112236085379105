// initial state
const state = {
  referrer: true,
};

// getters
const getters = {
  referrer: (state) => state.referrer,
};

// actions
const actions = {
  setReferrer({ commit }, boolean) {
    commit('setReferrer', boolean);
  },
};

// mutations
const mutations = {
  setReferrer(state, boolean) {
    state.referrer = boolean;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
