/*
 * Store to manage the state of loading elements in the app
 */

// initial state
const state = {
  globalLoaderBusy: false,
};

// getters
const getters = {
  globalLoaderBusy: (state) => state.globalLoaderBusy,
};

// actions
const actions = {
  setGlobalLoaderBusy({ commit }, busy) {
    commit('setGlobalLoaderBusy', busy);
  },
};

// mutations
const mutations = {
  setGlobalLoaderBusy(state, busy) {
    state.globalLoaderBusy = busy;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
