import { ifAuthenticated } from '../../router/routing_helpers';

export default [
  {
    path: '/document/:documentId/:highlightWord?',
    redirect: { name: 'nlDocument' },
  },
  {
    path: '/nl/document/:documentId/:highlightWord?',
    name: 'nlDocument',
    beforeEnter: ifAuthenticated,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDetail" */ './views/DocumentDetail.vue'),
    props: { dashboard: 'nl' },
  },
  {
    path: '/eu/document/:documentId/:highlightWord?',
    name: 'euDocument',
    beforeEnter: ifAuthenticated,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDetail" */ './views/DocumentDetail.vue'),
    props: { dashboard: 'eu' },
  },
  {
    path: '/nl-lr/document/:documentId/:highlightWord?',
    name: 'nlLrDocument',
    beforeEnter: ifAuthenticated,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDetail" */ './views/DocumentDetail.vue'),
    props: { dashboard: 'nlLr' },
  },
];
