// initial state
const state = {
  message: null,
};

// getters
const getters = {
  getMessage: () => state.message,
};

// actions
const actions = {
  clearMessages({ commit }) {
    commit('clearMessages');
  },
  setMessage({ commit }, message) {
    commit('setMessage', message);
  },
};

// mutations
const mutations = {

  clearMessages(state) {
    state.message = null;
  },

  setMessage(state, message) {
    state.message = message;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
