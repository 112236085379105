import ConfigServer from '@/config.server';
import authenticationAPI from '../../api/authentication';

// initial state
const defaultState = {
  isAuthenticated: false,
  sessionToken: null,
  roles: [],
  activeModules: [],
  firstName: null,
  lastName: null,
};

const state = JSON.parse(localStorage.getItem('authenticationState')) || defaultState;

// getters
const getters = {
  userName: ({ firstName, lastName }) => `${firstName} ${lastName}`,
  authIsAuthenticated: (state) => state.isAuthenticated,
  authRoles: (state) => state.roles,
  authSessionToken: (state) => state.sessionToken,
  authHasRole: (state) => (role) => state.roles.indexof(role) > -1,
  authActiveModules: (state) => state.activeModules,
  authHasModuleAccess: (state) => (moduleCode, moduleConfiguration) => {
    // Needed to avoid issues when activeModules has never been stored yet
    // Can be removed when all users have logged out and logged in again
    if (!state.activeModules) {
      state.activeModules = [];
    }

    const matchingModule = state.activeModules.find((module) => module.moduleStatus
      .isActive && module.moduleStatus.moduleCode === moduleCode);

    if (!matchingModule) {
      return false;
    }

    if (moduleConfiguration) {
      let hasAccess = true;
      Object.keys(moduleConfiguration).forEach((key) => {
        if (hasAccess && Object.prototype.hasOwnProperty.call(moduleConfiguration, key)
          && matchingModule.moduleConfiguration[key] !== moduleConfiguration[key]) {
          hasAccess = false;
        }
      });
      if (!hasAccess) {
        return false;
      }
    }

    return true;
  },
};

// actions
const actions = {

  /**
   * Logs the user in and sets the received token in the state
   *
   * @param {any} { commit }
   */
  async login({ commit }, payload) {
    // Reset all errors
    commit('clearMessages');

    try {
      const response = await authenticationAPI.login(payload);
      const {
        token,
        activeModules,
        firstName,
        lastName,
      } = response.data;

      if (token) {
        commit('setIsAuthenticated', true);
        commit('setSessionToken', token);
        commit('setSessionTokenAsCookie');
        commit('setActiveModules', activeModules);
        commit('setSessionName', { firstName, lastName });
      }
      return response;
    } catch {
      return null;
    }
  },

  clearCache({ commit }) {
    commit('setUserRoles', []);
    commit('setIsAuthenticated', false);
    commit('setSessionToken', null);
    commit('setSessionTokenAsCookie');
    commit('setActiveModules', []);
    // Check if legacy userState key exists in localStorage if so remove it.
    // See: https://stackoverflow.com/questions/3262605/how-to-check-whether-a-storage-item-is-set
    if (localStorage.getItem('userState') !== null) {
      localStorage.removeItem('userState');
    }
  },

  /**
   * Logs the out and resets the state
   *
   * @param {any} { commit }
   */
  async logout({ dispatch }) {
    const response = await authenticationAPI.logout();

    dispatch('clearCache');

    return response;
  },

  /**
   * Sets if the user is authenticated
   *
   * @param {any} { commit }
   */
  async setIsAuthenticated({ commit }, isAuthenticated) {
    commit('setIsAuthenticated', isAuthenticated);
  },

  /**
   * Sets if the user is authenticated
   *
   * @param {any} { commit }
   */
  async setSessionToken({ commit }, sessionToken) {
    commit('setSessionToken', sessionToken);
    commit('setSessionTokenAsCookie');
  },

  async setSessionTokenAsCookie({ commit }) {
    commit('setSessionTokenAsCookie');
  },

  /**
   * sets the user's roles
   *
   * @param {any} { commit }
   */
  async setUserRoles({ commit }, roles) {
    commit('setUserRoles', roles);
  },

};

// mutations
const mutations = {
  setUserRoles(state, roles) {
    state.roles = roles;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setSessionToken(state, sessionToken) {
    state.sessionToken = sessionToken;
    localStorage.setItem('authenticationState', JSON.stringify(state));
  },
  setSessionTokenAsCookie(state) {
    // Copy the authToken to a cookie, since some requests can't send it as header.
    // For example the request made by an audio-elem.
    // Note this is only for LOCAL where the different FE & BE domains make it impossible to
    // set a cookie from the BE. So we set this less-safe cookie from FE instead.
    // Note the lack if the HttpOnly flag!
    // 28800s = 8h
    if (ConfigServer.polpo_env === 'local') {
      const domain = new URL(ConfigServer.address).hostname;
      document.cookie = `jwtToken=${state.sessionToken}; Path=/; Domain=${domain}; SameSite=Strict; Secure; Max-Age=28800`;
    }
  },
  setSessionName(state, { firstName, lastName }) {
    state.firstName = firstName;
    state.lastName = lastName;
    localStorage.setItem('authenticationState', JSON.stringify(state));
  },
  setActiveModules(state, activeModules) {
    state.activeModules = activeModules;
    localStorage.setItem('authenticationState', JSON.stringify(state));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
