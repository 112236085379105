import documentApiConstructor from '@/api/document/document-api';

const documentApi = documentApiConstructor('nl');

// initial state
const state = {
  nlDocument: null,
  nlRelated: null,
};

// getters
const getters = {
  nlDocument: (state) => state.nlDocument,
  nlRelated: (state) => state.nlRelated,
  nlRelatedParent: (state) => state.nlRelatedParent,
};

// actions
const actions = {

  /**
   * Retreives document by its api endpoint url
   *
   * @param {any} { commit }
   */
  async nlGetDocumentById({ commit }, args) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const document = await documentApi.getById(args.id, { highlightWord: args.highlightWord });
    commit('setDocument', document.data);
    return document;
  },

  async getRelated({ commit }, refs) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const related = await documentApi.getRelated(refs);
    commit('setDocumentRelated', related.data);
    return related;
  },
};

// mutations
const mutations = {
  setDocument(state, document) {
    state.nlDocument = document;
  },

  setDocumentRelated(state, related) {
    state.nlRelated = related;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
