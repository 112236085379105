import { ifAuthenticated, loginAuthenticate } from '../../router/routing_helpers';

export default [
  {
    path: '/agenda',
    name: 'agendaDashboard',
    beforeEnter: loginAuthenticate,
    component: () => import(/* webpackChunkName: "AgendaOverview" */ './views/AgendaOverview.vue'),
  },
  {
    path: '/agenda/:agendaId',
    name: 'agenda-detail',
    beforeEnter: ifAuthenticated,
    // Lazy loading agenda
    component: () => import(/* webpackChunkName: "AgendaDetail" */ './views/AgendaDetail.vue'),
  },
];
