import polpoApi from '@/api/polpoApi';

export default (apiGroup) => ({
  // Added timestamp because IE11 is cashing get requests too aggressive
  // See: https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache
  getAll: async (data) => polpoApi.post(`${apiGroup}/savedsearches/user?timestamp=${new Date().getTime()}`, data),
  save: async (search) => polpoApi.post(`${apiGroup}/savedsearches`, search),
  delete: async (data) => polpoApi.delete(`${apiGroup}/savedsearches`, data),
  update: async (data) => polpoApi.put(`${apiGroup}/savedsearches`, data),
  updates: async (data) => polpoApi.patchRaw(`${apiGroup}/savedsearches/user/`, data),
});
