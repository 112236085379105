import { loginAuthenticate } from '../../router/routing_helpers';

export default [
  {
    path: '/',
    redirect: { name: 'nlDashboard' },
  },
  {
    path: '/dashboard',
    redirect: { name: 'nlDashboard' },
  },
  {
    path: '/nl/dashboard',
    name: 'nlDashboard',
    beforeEnter: loginAuthenticate,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDashboard" */ './views/DocumentDashboard.vue'),
    props: { dashboard: 'nl' },
  },
  {
    path: '/eu/dashboard',
    name: 'euDashboard',
    beforeEnter: loginAuthenticate,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDashboard" */ './views/DocumentDashboard.vue'),
    props: { dashboard: 'eu' },
  },
  {
    path: '/nl-lr/dashboard',
    name: 'nlLrDashboard',
    beforeEnter: loginAuthenticate,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDashboard" */ './views/DocumentDashboard.vue'),
    props: { dashboard: 'nlLr' },
  },
];
