// App specific
import fileApi from '@/api/document/document-api-file';

const initialState = {
  checkFile: false,
  file: null,
  fileMissing: false,
};

// initial state
let state = {
  ...initialState,
};

// getters
const getters = {
  checkFile: (state) => state.checkFile,
  file: (state) => state.file,
  fileMissing: (state) => state.fileMissing,
};

// actions
const actions = {

  /**
     * Retrieves whether or not a file exists for a particular document from the backend API
     *
     * @param {any} { commit }
     * @param {String} args.docType - Document type of the document to check if a file exists for
     * @param {String} args.docId - ID of the document to check if a file exists for
     */
  async checkFile({ commit }, args) {
    const response = await fileApi.checkFile(args.docType, args.docId, { mimeType: args.mimeType });
    if (response.data.access === 'Granted') commit('setCheckFile', true);
    else commit('setCheckFile', false);
  },

  /**
     * Retrieves the file data of a particular document from the backend API
     *
     * @param {any} { commit }
     * @param {String} args.docType - Document type of the document to get file data for
     * @param {String} args.docId - ID of the document to get file data for
     */
  async getFile({ commit }, {
    docType, docId, mimeType, timeout,
  }) {
    try {
      const response = await fileApi.getFile(docType, docId, { mimeType }, timeout);
      if (response.data.byteLength > 140) commit('setFile', response.data);
      else commit('setFileMissing');
    } catch (_) {
      commit('setFileMissing');
    }
  },

  setFile: ({ commit, state }, newValue) => {
    commit('setFile', newValue);
    return state.file;
  },

  setCheckFile: ({ commit, state }, newValue) => {
    commit('setCheckFile', newValue);
    return state.checkFile;
  },

  clearAllFileData({ commit }) {
    commit('clearAllFileData');
  },
};

// mutations
const mutations = {
  setCheckFile(state, checkFile) {
    state.checkFile = checkFile;
  },

  setFile(state, file) {
    state.file = file;
  },

  setFileMissing(state) {
    state.fileMissing = true;
  },

  clearAllFileData() {
    state = {
      ...initialState,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
