import polpoApi from '@/api/polpoApi';

export default {
  // The timestamp thingies are here due to IE11
  // See: https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache
  getAll: async () => polpoApi.get(`agenda/savedsearches/user?timestamp=${new Date().getTime()}`),
  save: async (search) => polpoApi.post('agenda/savedsearches', search),
  delete: async (data) => polpoApi.delete('agenda/savedsearches', data),
  update: async (data) => polpoApi.put('agenda/savedsearches', data),
  updates: async (data) => polpoApi.patchRaw('agenda/savedsearches/user/', data),
  exportExcel: async (data) => polpoApi.postExcel('agenda/savedsearches/export/xlsx', data),
};
