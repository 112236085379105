// App specific
import autocompleteAPI from '../../api/autocomplete';

// initial state
const state = {
  query: [],
};

// getters
const getters = {
  getAutocompleteQuery: (state) => state.query,
};

// actions
const actions = {

  /**
   * Retreives autocompletion for query
   *
   * @param {any} { state, commit, rootState }
   */
  async getAutocompleteByQuery({ commit }, query) {
    const response = await autocompleteAPI.getByQuery(query);
    commit('setAutocompleteQuery', response.data);
  },
};

// mutations
const mutations = {
  setAutocompleteQuery(state, autocompletionData) {
    state.query = autocompletionData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
