import polpoApi from '@/api/polpoApi';

export default {
  // The timestamp thingies are here due to IE11
  // See: https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache
  get: async (id) => polpoApi.get(`lists/${id}?timestamp=${new Date().getTime()}`),
  getArchived: async () => polpoApi.get(`lists/archived?timestamp=${new Date().getTime()}`),
  getActive: async () => polpoApi.get(`lists/active?timestamp=${new Date().getTime()}`),
  save: async (list) => polpoApi.post('lists', list),
  delete: async (list) => polpoApi.delete('lists', list),
  update: async (list) => polpoApi.put('lists', list),
  getListItems: async (id) => polpoApi.get(`lists/${id}/list-items?timestamp=${new Date().getTime()}`),
  getListExportHTMLPreviewToken: async (id) => polpoApi.get(`lists/${id}/export/html-token?timestamp=${new Date().getTime()}`),
  getHTMLExport: async (id, token, locale) => polpoApi.get(`lists/${id}/export/html/${locale}/${token}`),
  verify: async (email) => polpoApi.post('lists/verify', { email }),
  transfer: async (email, listIds) => polpoApi.patchRaw('lists/transfer', { email, listIds }),
  sort: async (list, desc) => polpoApi.patchRaw(`lists/${list}/list-items/sort`, { desc }),
  duplicate: async (list) => polpoApi.post(`lists/${list}/duplicate`),
  clearList: async (listId) => polpoApi.deleteRaw(`lists/${listId}/list-items/delete`),
};
