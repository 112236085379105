const state = {
  confirmDialogHeader: 'Header',
  confirmDialogBody: 'Body',
};

// getters
const getters = {
  confirmDialogHeader: (state) => state.confirmDialogHeader,
  confirmDialogBody: (state) => state.confirmDialogBody,
};

// actions
const actions = {
  setConfirmDialogHeader({ commit }, payload) {
    commit('setConfirmDialogHeader', payload);
  },
  setConfirmDialogBody({ commit }, payload) {
    commit('setConfirmDialogBody', payload);
  },
};

// mutations
const mutations = {
  setConfirmDialogHeader(state, payload) {
    state.confirmDialogHeader = payload;
  },
  setConfirmDialogBody(state, payload) {
    state.confirmDialogBody = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
