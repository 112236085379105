// import polpoApi from './polpoApi';

export default {
  // getByQuery: async (query) => polpoApi.getById('autocomplete/query', query),
  getByQuery: async () => ({
    data: [
      { value: 'politie' },
      { value: 'politiek' },
      { value: 'politieke partijen' },
    ],
  }),
};
