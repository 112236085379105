import documentApiConstructor from '@/api/document/document-api';

const documentApi = documentApiConstructor('eu');

// initial state
const state = {
  euDocument: null,
  euDocumentRelated: null,
};

// getters
const getters = {
  euDocument: (state) => state.euDocument,
  euDocumentRelated: (state) => state.euDocumentRelated,
};

// actions
const actions = {
  async euGetDocumentById({ commit }, args) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const document = await documentApi.getById(args.id, { highlightWord: args.highlightWord });
    commit('euSetDocument', document.data);
    return document;
  },

  async euGetRelated({ commit }, refs) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const related = await documentApi.getRelated(refs);
    commit('euSetDocumentRelated', related.data);
    return related;
  },
};

// mutations
const mutations = {
  euSetDocument(state, document) {
    state.euDocument = document;
  },

  euSetDocumentRelated(state, related) {
    state.euDocumentRelated = related;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
