// initial state
const state = {
  isIE11: false,
};

// getters
const getters = {
  isIE11: (state) => state.isIE11,
};

// actions
const actions = {
  setIsIE11({ commit }) {
    commit('setIsIE11');
  },
};

// mutations
const mutations = {
  setIsIE11(state) {
    state.isIE11 = (!!window.MSInputMethodContext && !!document.documentMode);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
